import React, { useContext } from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import SessionContext from 'src/context/SessionContext';
import { FAQSection, QuotesSection, HowItWorksSection } from 'src/sections';
import { KEYWORDS } from 'src/data';
import { Benefit } from 'src/types/session';
import { $t, locale } from 'src/utils';
import {
  Page,
  Section,
  Lineup,
  FeatureBox,
  FeatureList,
  NewHero,
  Button,
  Zip,
} from 'src/components';
import { Segment, SegmentEvent } from 'src/lib';

const QUERY = graphql`
  query HealthFaq {
    en: allGraphCmsFaq(filter: { contexts: { in: health }, locale: { eq: en } }) {
      nodes {
        title
        explanation
      }
    }
    es: allGraphCmsFaq(filter: { contexts: { in: health }, locale: { eq: es } }) {
      nodes {
        title
        explanation
      }
    }
  }
`;

const RENEWAL_FLOW_USER_PERCENTAGE = 0.5;

/**
 * @todo add co-brand logo
 */
const Health = ({ pageContext }) => {
  const data = useStaticQuery(QUERY);
  const faqs = data?.[locale]?.nodes;
  const {
    partner,
    source: _source,
    code,
    name,
    personas = [],
    title,
    logo,
    state,
    color,
    color2,
    colorLight,
    image = '/img/og/health.png',
    imageData,
  } = pageContext;

  const PX_CTX = Math.random() < RENEWAL_FLOW_USER_PERCENTAGE ? 'HEALTH_RENEW' : 'HEALTH_EXPRESS';

  const {
    source,
    setSource,
    setCode,
    setVertical,
    identify,
    setPartner,
    setHealthContext,
    signupUrl,
    pxId,
    adminPxLink,
  } = useContext(SessionContext);

  if (setSource) {
    if (_source) setSource(_source);
    if (partner) setPartner(partner);
    if (code || _source || partner) setCode(code || _source || partner);
    setVertical('health');
    identify();
  }

  const handleNext = (healthCtx: Benefit, url) => {
    Segment.track(SegmentEvent.PX_STARTED, {
      flow: healthCtx === 'HEALTH_RENEW' ? 'Renew' : 'Explorer',
      healthContext: PX_CTX,
      partner: code || name || partner,
      pxId,
      adminPxLink,
    });

    setHealthContext(healthCtx);
    window.location = (url || signupUrl)?.replace(/(benefit=[A-Z])\w+/, `benefit=${healthCtx}`);
  };

  const _color = color2 || color;

  return (
    <Page
      skipLd={!!title}
      signupUrl={signupUrl}
      image="/img/og/health.png"
      vertical="HEALTH"
      prefooter={<></>}
      navBorder={false}
      hideLinks={!!partner}
      metadata={{
        faqs,
        image,
        // path: 'benefits/health', @see https://catchco.slack.com/archives/D021E5ZST7U/p1668023499342669
        title: !!title
          ? title?.replace('Benefits for', 'Health and Dental insurance for')
          : !!name
          ? `Health and Dental insurance for ${name}`
          : 'Health and Dental insurance with Catch',
        description: !!state
          ? `Save thousands on ACA compliant ${state} health insurance. Built for freelancers, contractors, and self-employed. Join today for free.`
          : 'Save thousands on health insurance. Built for freelancers, contractors, and self-employed. Join today for free.',
        keywords: [
          ...KEYWORDS['Health'],
          `${state} health insurance`,
          `${state} health coverage`,
          `${state} marketplace`,
          partner,
          source,
          name,
          ...personas,
        ],
      }}
    >
      <NewHero
        image={imageData}
        color={_color}
        colorLight={colorLight}
        logo={logo}
        eb={
          !logo &&
          $t({
            id: 'benefits/health.hero-pretitle',
            defaultMessage: 'Health & Dental',
          })
        }
        title={$t({
          id: 'benefits/health.hero-title',
          defaultMessage:
            'Health and Dental insurance {name, select, undefined {with Catch} other {for {name}} }',
          data: { name: name?.toProperCase() },
        })}
        subtitle={$t({
          id: 'benefits/health.hero-subtitle',
          defaultMessage:
            'The fastest way to compare hundreds of health and dental plans. Free support from licensed agents. Savings automatically applied.',
        })}
      >
        <Zip />
      </NewHero>

      <Section>
        <Lineup tight height={64} nowrap style={{ marginLeft: -24, marginRight: -24 }}>
          {['oscar', 'aetna', 'cigna', 'ambetter', 'kaiser', 'delta', 'bcbs']?.map((logo) => (
            <img
              loading="lazy"
              style={logoStyle}
              key={logo}
              alt={logo}
              src={`https://s.catch.co/img/carriers/${logo}.png`}
            />
          ))}
        </Lineup>
        <h3 style={{ marginTop: 24, marginBottom: 120, textAlign: 'center', fontWeight: 400 }}>
          {$t({
            id: 'benefits/health.carriers-title',
            defaultMessage: 'ACA-compliant plans from 200+ carriers nationwide',
          })}
        </h3>
      </Section>
      <QuotesSection small />
      <Section small>
        <FeatureBox
          eb={$t({
            id: 'benefits/health.feature-1-pretitle',
            defaultMessage: 'Save',
          })}
          title={$t({
            id: 'benefits/health.feature-1-title',
            defaultMessage: 'Save thousands with tax credits',
          })}
          subtitle={$t({
            id: 'benefits/health.feature-1-subtitle',
            defaultMessage: 'Get the lowest price on the coverage you need',
          })}
          fullImage="explore.png"
          color={_color}
          ebColor={color}
        >
          <FeatureList
            items={[
              $t({
                id: 'benefits/health.feature-1-bullet-1',
                defaultMessage: '90% qualify to save',
              }),
              $t({
                id: 'benefits/health.feature-1-bullet-2',
                defaultMessage: '$511/mo. in average savings',
              }),
            ]}
          />
        </FeatureBox>
        <FeatureBox
          eb={$t({
            id: 'benefits/health.feature-2-pretitle',
            defaultMessage: 'Shop',
          })}
          title={$t({
            id: 'benefits/health.feature-2-title',
            defaultMessage: 'Compare health plans',
          })}
          subtitle={$t({
            id: 'benefits/health.feature-2-subtitle',
            defaultMessage:
              'Make sure you know what you’re choosing and pick the best plan for your needs',
          })}
          fullImage="compare.png"
          color={_color}
          ebColor={color}
        />
        <FeatureBox
          eb={$t({
            id: 'benefits/health.feature-3-pretitle',
            defaultMessage: 'Trust',
          })}
          title={$t({
            id: 'benefits/health.feature-3-title',
            defaultMessage: 'ACA-compliant plans only',
          })}
          subtitle={$t({
            id: 'benefits/health.feature-3-subtitle',
            defaultMessage: 'You’ll only see plans that cover all of the Essential Health Benefits',
          })}
          fullImage="explore-detail.png"
          color={_color}
          ebColor={color}
        />
      </Section>

      <HowItWorksSection />

      <Section
        dark
        bg={color2 || 'var(--c-bg-2)'}
        inset
        pretitle={$t({
          id: 'benefits/health.oe-pretitle',
          defaultMessage: 'Open Enrollment 2024',
        })}
        title={$t({
          id: 'benefits/health.oe-title',
          defaultMessage: 'Connect your coverage. Catch will take it from there.',
        })}
      >
        <Button onPress={() => handleNext('HEALTH_RENEW')}>
          {$t({
            id: 'benefits/health.oe-btn',
            defaultMessage: 'Easy renew',
          })}
        </Button>
      </Section>
      <FAQSection color={color || 'var(--c-red-0)'} faqs={faqs} theme="light" />
      <Section
        narrow
        title={$t({
          id: 'benefits/health.zip-title',
          defaultMessage: 'See how much you could save',
        })}
        subtitle={$t({
          id: 'benefits/health.zip-subtitle',
          defaultMessage:
            'Find tax credits you may have missed to lower your health insurance premiums. ',
        })}
        bg={color2 || 'var(--g-red)'}
        inset
        dark
      >
        <Zip />
      </Section>
    </Page>
  );
};

const logoStyle = {
  borderRadius: 99,
  background: 'var(--c-border)',
};

export default Health;
